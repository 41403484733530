jQuery(function($) {
  //first unbind all actions connected to our automatic table of content links, otherwise themes like Divi overrule our smooth scrolling preferences, use timeout to correctly apply code without being to early
  setTimeout(function() {
    $('a.ah-automatic-table-of-contents-link[href*="#"]').unbind();
  }, 1000);

  var scroll = new SmoothScroll('a.ah-automatic-table-of-contents-link[href*="#"]',
    {
      offset: affiliate_hero_public_modules_automatic_table_of_contents.smooth_scroll_offset,
      speed: affiliate_hero_public_modules_automatic_table_of_contents.smooth_scroll_speed,
      speedAsDuration: true
    }
  );
});
